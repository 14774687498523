import React from "react";
import "./ForgotPassword.css"; // We'll add styles for this page

function ForgotPassword() {
  return (
    <div className="forgot-password-container">
      <h1>Reset Your Password</h1>
      <p>Please enter your email address, and we'll send you a link to reset your password.</p>
      <form>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            required
          />
        </div>
        <button type="submit" className="btn-submit">
          Send Reset Link
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
